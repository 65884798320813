import React, { useEffect } from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";
import './../theme/comp/giftcards.scss';

declare global {
  interface Window {
    launchShortcutsWidget: () => void;
  }
}

const Giftcards: React.FC = () => {

  const mapImage = process.env.PUBLIC_URL + '/images/giftcards.png';

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.type = 'application/javascript';
  //   script.async = true;
  //   script.id = 'sc-widget_launcher';
  //   script.src = 'https://d19ujuohqco9tx.cloudfront.net/ols-prod-giftcard-ui/widget-injector/sc-widget-launcher.js';
  //   script.dataset.ruleset_id = '6706';
  //   script.dataset.currency_code = 'CAD';
  //   script.dataset.activation_option = '3';
  //   script.dataset.widget_label = 'Buy a gift card';
  //   script.dataset.liable_site_id = '67397';
  //   script.dataset.popup_width = '480';
  //   script.dataset.popup_height = '840';
  //   script.dataset.host = 'https://d19ujuohqco9tx.cloudfront.net/ols-prod-giftcard-ui';
  //   document.head.appendChild(script);

  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);

  const bookingLink: string = 'https://trendzone.zenoti.com/webstoreNew/giftcards';
  const handleOpenLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <main>
      <div className="header-gap"></div>
      <Header />

      <div className="margin-bottom-48">
        <div className="grid text-center">
          <h1 className="headline-text">Trendzone Giftcards</h1><br />
          <div className="flex-std-res">
            <img className='map-image' src={mapImage} alt='map-image' />
            <h2 className="description-text">Give the gift of style and confidence with a Trendzone Barbershop Gift Card! Perfect for every occasion, this gift card allows your loved ones to enjoy top-notch grooming services, including haircuts, beard trims, shaves, and more. Whether it’s a fresh fade, a classic cut, or a relaxing grooming session, Trendzone Barbershop is where style meets expertise.</h2>
          </div>
        </div>
      </div>

      <div className="flex-std">
        {/* <button id="sc-widget_launcher_button" className="btn-std btn-large" onClick={() => window.launchShortcutsWidget && window.launchShortcutsWidget()}>
          Purchase Giftcard
        </button> */}
        <button className="btn-std btn-large" onClick={() => handleOpenLink(bookingLink)}>
          Purchase Giftcard
        </button>
      </div>

      <Footer />
    </main>
  );
}

export default Giftcards;