import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

import BookPopover from "../popovers/Book.popover";

import './../theme/comp/header.scss';
import './../theme/gen/logo.scss';

interface HeaderProps {
    solidHeader?: boolean;
}

const Header: React.FC<HeaderProps> = ({ solidHeader = false }) => {

    const linksData = [
        { id: 2, title: "Services", path: "/services" },
        { id: 3, title: "Giftcards", path: "/giftcards" },
        // { id: 4, title: "Contact", path: "/contact" }
    ]

    const [header, setHeader] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const scrollHeader = () => {
        if (window.scrollY > 500) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    };

    const bookingLink: string = 'https://trendzone.zenoti.com/webstoreNew';
    const handleOpenLink = (url: string) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHeader);

        return () => {
            window.removeEventListener('scroll', scrollHeader);
        }
    }, []);

    return (
        <header className={`${header || solidHeader ? 'site-header-scrolled' : ''} site-header-parent`}>
            {/* <div className="message-box"><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum, earum magni numquam nam fugiat, dolore iure impedit minima iste quas fuga laboriosam eius rerum, id voluptas architecto in aut vitae iusto nisi molestias odit. Sint illo nisi praesentium, earum natus delectus dolor recusandae in cum quo voluptatum, possimus eveniet.</p></div> */}
            <div className="site-header">
                <div className="site-header-logo-section" style={{ '--site-logo-url': `url(${process.env.PUBLIC_URL}/logos/tzlogohorizontal.svg)` } as React.CSSProperties}>
                    <Link to='/'><div className="site-logo cursor-pointer" /></Link>
                </div>
                <div className="site-header-button-section">

                    <ul className=''>
                        {linksData.map((links) => (
                            <li key={links.id}>
                                <NavLink className={({ isActive }) => isActive ? 'text-uppercase header-active-link' : 'text-uppercase'} to={links.path}>{links.title}</NavLink>
                            </li>
                        ))}
                    </ul>

                    {/* <button className="btn-std" onClick={() => setPopoverOpen(!popoverOpen)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="button-icon" viewBox="0 0 512 512"><circle cx="104" cy="152" r="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><circle cx="104" cy="360" r="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path d="M157 175l-11 15 37 15s3.46-6.42 7-10z" fill="none" stroke="currentColor" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="32" /><path d="M154.17 334.43L460 162c-2.5-6.7-28-12-64-4-29.12 6.47-121.16 29.05-159.16 56.05C205.85 236.06 227 272 192 298c-25.61 19-44.43 22.82-44.43 22.82zM344.47 278.24L295 306.67c14.23 6.74 65.54 33.27 117 36.33 14.92.89 30 .39 39-6z" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" /><circle cx="256" cy="240" r="32" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /></svg>
                        Book
                    </button> */}

                    <button className="btn-std" onClick={() => handleOpenLink(bookingLink)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="button-icon" viewBox="0 0 512 512"><circle cx="104" cy="152" r="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><circle cx="104" cy="360" r="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path d="M157 175l-11 15 37 15s3.46-6.42 7-10z" fill="none" stroke="currentColor" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="32" /><path d="M154.17 334.43L460 162c-2.5-6.7-28-12-64-4-29.12 6.47-121.16 29.05-159.16 56.05C205.85 236.06 227 272 192 298c-25.61 19-44.43 22.82-44.43 22.82zM344.47 278.24L295 306.67c14.23 6.74 65.54 33.27 117 36.33 14.92.89 30 .39 39-6z" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" /><circle cx="256" cy="240" r="32" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /></svg>
                        Book
                    </button>

                    <BookPopover show={popoverOpen} onClose={() => setPopoverOpen(false)} />

                </div>
            </div>
        </header>
    );
}

export default Header;